/* eslint-disable */
$(function() {
    $(".js-phone").mask("+7 (999) 999-99-99");


	if ($('#map').length) {
        var init = function init() {
            var myMap = new ymaps.Map("map", {
                center: [47.283682, 39.857072],
                zoom: 15,
            });

            var myPlacemark = new ymaps.Placemark(myMap.getCenter(), {}, {
                iconLayout: 'default#image',
                iconImageHref: './images/common/marker.svg',
                iconImageSize: [42, 59],
                iconImageOffset: [-21, -59]
            });
            
            myPlacemark.events.add('click', function () {
                window.open("https://yandex.ru/maps/39/rostov-na-donu/?ll=39.857073%2C47.283682&mode=routes&rtext=~47.283682%2C39.857072&rtt=auto&ruri=~ymapsbm1%3A%2F%2Fgeo%3Fll%3D39.857%252C47.284%26spn%3D0.001%252C0.001%26text%3D%25D0%25A0%25D0%25BE%25D1%2581%25D1%2581%25D0%25B8%25D1%258F%252C%2520%25D0%25A0%25D0%25BE%25D1%2581%25D1%2582%25D0%25BE%25D0%25B2%25D1%2581%25D0%25BA%25D0%25B0%25D1%258F%2520%25D0%25BE%25D0%25B1%25D0%25BB%25D0%25B0%25D1%2581%25D1%2582%25D1%258C%252C%2520%25D0%2590%25D0%25BA%25D1%2581%25D0%25B0%25D0%25B9%252C%2520%25D0%25BF%25D1%2580%25D0%25BE%25D1%2581%25D0%25BF%25D0%25B5%25D0%25BA%25D1%2582%2520%25D0%259B%25D0%25B5%25D0%25BD%25D0%25B8%25D0%25BD%25D0%25B0%252C%252042&z=17.09", '_blank');
            });
			
			myMap.controls.remove('zoomControl');
            myMap.behaviors.disable('scrollZoom');
            myMap.behaviors.disable('drag');
            myMap.geoObjects.add(myPlacemark);
        };
    
        ymaps.ready(init);
    }

    $(".js-submit").on("click" , function (event) {
        $(this).parents(".js-form-block").find(".js-form-hidden").hide();
        $(this).parents(".js-form-block").find(".js-form-ok").show();
        return false;
    });
    
    $(".js-anchor").on("click" , function (event) {
        if ($(this).parents('.js-menu').hasClass("menu--mobile")) {
            $(".js-menu").removeClass("is-active");
            $(".js-gamburger").removeClass("is-active");
        }

        event.preventDefault();
        let id  = $(this).attr('href'),
            top = $(id).offset().top - 80;
        $('body,html').animate({scrollTop: top}, 1200);        
    });
	
	$(".js-link-map").on("click" , function (event) {
        event.preventDefault();
        let id  = $(this).attr('href'),
            top = $(id).offset().top;
        $('body,html').animate({scrollTop: top}, 1500);
    });


    $(".js-gamburger").on("click" , function (event) {
        $(this).toggleClass("is-active");
        $(".js-menu").toggleClass("is-active");
    });

    $(".js-device-color").on("click" , function (event) {
        let color = $(this).data("color");

        $(".js-device-type").removeClass("is-active");
        $(".js-device-type[data-color="+color+"]").addClass("is-active");
        $(".js-device-color").removeClass("is-active");
        $(this).addClass("is-active");

    });

    if ($(window).width() < 960) {
        $(".js-device-item").on("click" , function (event) {

            if (!$(this).hasClass("is-click")) {
                $(".js-device-item").addClass("is-hidden").removeClass("is-click");
                $(this).addClass("is-click").removeClass("is-hidden");
            } else {
                $(".js-device-item").removeClass("is-hidden").removeClass("is-click");
                $(this).removeClass("is-click").removeClass("is-hidden");
            }
        });
    } else {
        $(".device__icon").on("mouseover" , function (event) {
            $(".js-device-item").addClass("is-hidden").removeClass("is-click");
            $(this).parents(".js-device-item").addClass("is-click").removeClass("is-hidden");
        });

        $(".js-device-item").on("mouseout" , function (event) {
            $(".js-device-item").removeClass("is-hidden").removeClass("is-click");
            $(this).removeClass("is-click").removeClass("is-hidden");
        });
    }



    $(".js-popup-open").on("click" , function (event) {
        let popupName = $(this).data("popup");

        $(".js-popup[data-popup="+popupName+"]").addClass("is-open");
    });

    $(".js-popup-close").on("click" , function (event) {
        $(".js-popup").removeClass("is-open");
    });

    $(".js-menu").swipe( {
        swipe:function(event, direction, distance, duration, fingerCount, fingerData) {
            if (direction == "up") {
                $(".js-gamburger").removeClass("is-active");
                $(".js-menu").removeClass("is-active");
            }
        }
    });

    $(".js-tech-more").on("click" , function (event) {
        $(this).toggleClass("is-active");
        
        if ($("body").hasClass("is-eng")) {
            if ( $(this).hasClass("is-active")) {
                $(this).find("span").text("collapse");
            } else {
                $(this).find("span").text("show all");
            }
    
        } else {
            if ( $(this).hasClass("is-active")) {
                $(this).find("span").text("свернуть");
            } else {
                $(this).find("span").text("показать все");
            }
    
        }

        $(this).prev(".js-tech-hidden").slideToggle(300, function() { })
    });

    let topBanner = 0;

    if ($(".js-main-banner").length) {
        let topBanner = $(".js-main-banner").offset().top;
    } 
    
    /***** PARALLAX BANNER *****/
    $(window).scroll(function(e){
        let top = $(window).scrollTop();

        parallaxScroll();

        deviceAnimation2(top);

        if (top > $(".js-info-block").offset().top + 120 - $(window).height()) {
            $(".js-info-block").addClass("is-active");
        }   

        if (top > $(".js-main-banner").height()/4) {
            let percent =  (top - ($(".js-main-banner").height()/4) )
            * 100
            / ($(".js-main-banner").height()/4);

            if (percent < 70) {
                $(".js-main-banner-before").css("opacity" , percent + '%');
            }
        } else {
            $(".js-main-banner-before").attr('style', ''); 
        }

        if (top > 0) {
            $(".header").addClass("is-fixed");
        } else {
            $(".header").removeClass("is-fixed");
        }

        if (top > (topBanner + $(".js-main-banner").height())) {
            $(".js-main-banner").addClass("is-hidden");
        } else {
            $(".js-main-banner").removeClass("is-hidden");
        }

        $(".js-device-item").removeClass("is-click").addClass("is-hidden");
    });

    let topYdevice = [0, 40, 40, 35, 80, 50],
        countYDevice = [0, 0, 0, 0, 0, 0];

    if ($(window).width() > 1440) {
        topYdevice = [0, 95, 158, 125, 226, 182];
        countYDevice = [0, 31, 59, 32, 156, 106];
    }
    function deviceAnimation2(top) {
        $(".js-device-item").each(function( index , elem ) {
            let topItem = $(elem).offset().top,
                pad = 0;

            pad = topYdevice[index] - ((top + $(window).height()/1.3) - topItem)    

            if ((top + $(window).height()/1.3) > topItem) {
                if (index != 0 ) {
                    if (pad > countYDevice[index] ) {
                        $(elem).css("margin-top" , "-"+pad+"px");
                    } else {
                        $(elem).find(".device__icon").css("opacity" , 1);
                    }

                } else {
                    $(elem).find(".device__icon").css("opacity" , 1);
                }

                $(elem).addClass("is-active");
                
            }

           
        });
    }

/*
    function deviceAnimation(top) {
        let startAnim = $(".js-device").offset().top - $(window).height(),
            heightDevice = $(".js-device").height() + $(window).height();

        if (top > startAnim) {
            $(".js-device").addClass("is-active");
        }

        $(".js-device-item").each(function( index , elem ) {
           

            if ((top + $(window).height() * 0.7) >  $(elem).offset().top) {
                $(elem).addClass("is-active");
            }
        });
    }*/

	function parallaxScroll(){
        let scrolled = $(window).scrollTop(),
            bigItem = 0.1,
            smallItem = 0.05,
            miniItem = 0.05;

        if ($(window).width() > 1440) {
            bigItem = 0.65;
            miniItem = 0.5;
            smallItem = 0.3;
        }
        
        $('.js-mainbanner-scroll').css('transform', 'translateY('+(scrolled*smallItem)+'px)');
        $('.js-mainbanner-scroll-big').css('transform', 'translateY('+(scrolled*bigItem)+'px)');
        $('.js-mainbanner-scroll-mini').css('transform', 'translateY('+(scrolled*miniItem)+'px)');
    }
    
    $(window).on("load", function() {
        $(".js-preloader").addClass("is-hidden");
    });

    $(window).scroll(function() {
        if ($(window).scrollTop() > 300) {
            $(".js-up").addClass('is-active');
        } else {
            $(".js-up").removeClass('is-active');
        }
    });
    
    $(".js-up").on('click', function(e) {
        e.preventDefault();
        $('html, body').animate({scrollTop:0}, '300');
    });
});